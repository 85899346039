'use client';

import Link from 'next/link';
export default function MailtoButton() {
  const subject = 'Fehler in meinen Bestandsdaten';
  const body = 'Sehr geehrte Damen und Herren. Bei der Vervollständigung meines Antrags von Aareal Aval ist mir folgender Fehler in den Bestandsdaten aufgefallen:';
  const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  return <Link href={mailtoLink} className="text-aareal-aval-light underline" data-sentry-element="Link" data-sentry-component="MailtoButton" data-sentry-source-file="mail-button.tsx">
      E-Mail Vorlage
    </Link>;
}