'use client';

import { use, useEffect } from 'react';
import { UserData } from '@/lib/types';
import { useData } from '../context/data-context';
import RowReadOnly from './rows/row-read-only';
export default function FormImmutableData({
  userData
}: {
  userData: Promise<UserData>;
}) {
  const data = use(userData);
  const {
    setData
  } = useData();
  useEffect(() => {
    setData(data);
  }, [data, setData]);
  return <div className="divide-y divide-gray-100" data-sentry-component="FormImmutableData" data-sentry-source-file="form-immutable-data.tsx">
      <RowReadOnly name="Name" value={data.immutableData.firstTenant.name} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
      {data.immutableData.secondTenant && <RowReadOnly name="Zweiter Mieter" value={data.immutableData.secondTenant.name} />}
      <RowReadOnly name="Adresse Ihrer neuen Wohnung" value={data.immutableData.apartment.address} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
      <RowReadOnly name="Mietkaution" value={data.immutableData.apartment.deposit_sum} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
      <RowReadOnly name="Beginn Mietvertrag" value={data.immutableData.apartment.rental_date} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
      <RowReadOnly name="Vermieter" value={data.immutableData.lessor.name} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
      <RowReadOnly name="Adresse des Vermieters" value={data.immutableData.lessor.address} data-sentry-element="RowReadOnly" data-sentry-source-file="form-immutable-data.tsx" />
    </div>;
}