export default function RowReadOnly({
  name,
  value
}: {
  name: string;
  value: string;
}) {
  return <div className="py-3 px-4 flex items-center justify-between" data-sentry-component="RowReadOnly" data-sentry-source-file="row-read-only.tsx">
      <span className="text-sm font-medium text-gray-900 text-left">{name}</span>
      <span className="text-sm text-gray-700 text-right">{value}</span>
    </div>;
}